import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import { getCard, updateCard } from "store/actions"
//redux
import { useSelector, useDispatch } from "react-redux"
import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"
import CardModal from "./CardsModal"
import { withTranslation } from "react-i18next"
import { checkPermission } from "helpers/functions"
import moment from "moment"

function CardList(props) {
  //meta title

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [cards, setCard] = useState(null)
  const [show, setShow] = useState(false)
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
    status: "active",
  })

  const dispatch = useDispatch()

  const {
    cardData,
    error,
    success,
    nextPage,
    currentPage,
    previousPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.cards)

  useEffect(() => {
    dispatch(getCard(filter))
  }, [dispatch, filter])

  useEffect(() => {
    if (!isEmpty(cardData) && !!isEdit) {
      setIsEdit(false)
    }
  }, [cardData])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCard(null)
    } else {
      setModal(true)
    }
  }

  const toggleShow = () => {
    setShow(!show)
  }

  const handleOrderClick = arg => {
    const cards = arg
    setCard({
      ...cards,
    })

    setIsEdit(true)

    toggle()
  }

  console.log("---carddd---", cards)
  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = cards => {
    setCard(cards)
    setDeleteModal(true)
  }

  const handleDeleteCard = () => {
    if (cards.id) {
      dispatch(updateCard({ id: cards.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getCard(filter))
    }
  }
  const handleOrderClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("Card ID"),
        accessor: "id",
        disableFilters: true,
        accessor: cellProps => {
          return <div>{cellProps.id}</div>
        },
      },

      {
        Header: props.t("Card Number"),
        accessor: "code",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{cellProps.code ?? "----"}</span>
        },
      },
      {
        Header: props.t("Customer Name"),
        accessor: "customer",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <span>
              {cellProps.customer
                ? cellProps.customer?.firstname +
                  " " +
                  cellProps.customer?.lastname
                : "----"}
            </span>
          )
        },
      },
      {
        Header: props.t("Card Type"),
        accessor: "code",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{cellProps.cardType?.name || "----"}</span>
        },
      },
      {
        Header: props.t("Parrinage Number"),
        accessor: "code",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{cellProps.cardType?.nbParrainage || "----"}</span>
        },
      },
      {
        Header: props.t("Join Date"),
        accessor: "createdAt",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <span>{moment(cellProps.createdAt).format("MMM DD . YYYY")}</span>
          )
        },
      },
      {
        Header: props.t("Card Price"),
        accessor: "code",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{cellProps.price || "----"}</span>
        },
      },
      {
        Header: props.t("currency"),
        accessor: "currency",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{cellProps.currency || "----"}</span>
        },
      },
      {
        Header: props.t("Payment Method"),
        accessor: "code",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{cellProps.paymentMethod?.nameEn || "----"}</span>
        },
      },
      {
        Header: props.t("Payment Status"),
        accessor: "paymentStatus",
        disableFilters: true,
        accessor: cellProps => {
          return <span>{cellProps.paymentStatus || "----"}</span>
        },
      },
      {
        Header: props.t("User Name"),
        accessor: "code",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <span>
              {cellProps.createdBy
                ? cellProps.createdBy?.firstname +
                  " " +
                  cellProps.createdBy?.lastname
                : "----"}
            </span>
          )
        },
      },

      {
        Header: props.t("Action"),
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="text-center">
              <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const cardData = cellProps.row.original
                  handleOrderClick(cardData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const cardData = cellProps.row.original
                  onClickDelete(cardData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  return (
    <React.Fragment>
      <CardModal modal={modal} toggle={toggle} isEdit={isEdit} card={cards} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCard}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Cards")}
            breadcrumbItem={props.t("Cards List")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={cardData}
                    //isGlobalFilter={true}
                    isAddOptions={true}
                    //isAddOptions={checkPermission(userRoles, ["MasterData", "Card", "Create"])}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={100}
                    setPageNumber={setPageNumber}
                    setLimit={handleLimit}
                    currentPage={currentPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
CardList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(CardList)
