import React, { useEffect, useRef, useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Form,
} from "reactstrap"
import {
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
} from "store/users/actions"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { withTranslation } from "react-i18next"
import { upload } from "helpers/api_helper"
import PhoneInput from "react-phone-input-2"
import { getMDMBrand, getRetailers } from "store/actions"

const UserAddEdit = props => {
  const { user, isEdit, modal, toggle } = props
  const dispatch = useDispatch()

  const [filter, setFilter] = useState({
    brandId: "",
    cityId: "",
    status: "active",
    page: 1,
    limit: 100,
  })
  const [phonePicker, setPhonePicker] = useState("")
  const { retailersData } = useSelector(state => state.retailers)
  const { brandData } = useSelector(state => state.brands)
  const [loading, setLoading] = useState(false)
  const fileRef = useRef()

  useEffect(() => {
    dispatch(getRetailers(filter))
    dispatch(getMDMBrand())
  }, [dispatch])

  console.log("retailersData", retailersData)

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      firstname: (user && user.firstname) || "",
      lastname: (user && user.lastname) || "",
      email: (user && user.email) || "",
      phone: (user && user.phone) || "",
      image_url: (user && user.image_url) || "",
      brandId: (user && user.brandId) || "",
      retailerId: (user && user.retailerId) || "",
      password: "",
      accountType: "retailer",
    },

    validationSchema: Yup.object({
      firstname: Yup.string().required(props.t("Please Enter Your First Name")),
      //.matches(props.t("^[a-zA-Z]+$", "only latin words")),
      lastname: Yup.string().required(props.t("Please Enter Your Last Name")),
      //.matches(props.t("^[a-zA-Z]+$", "only latin words")),
      email: Yup.string().required(props.t("Please Enter Your Email")),
      brandId: Yup.string().required(props.t("Please select the retailer")),
      retailerId: Yup.string().required(props.t("Please select the store")),
      // password: Yup.string().required(props.t("Please Enter Your Password")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: user.id,
          firstname: values.firstname,
          lastname: values.lastname,
          phone: values.phone,
          image_url: values.image_url,
          brandId: values.brandId,
          retailerId: values.retailerId,
          ...(values.password && { password: values.password }),
          email: values.email,
          accountType: "retailer",
        }
        console.log(updateUser)
        // update user
        dispatch(onUpdateUser(updateUser))
        validation.resetForm()
        // setIsEdit(false)
      } else {
        const newUser = {
          firstname: values["firstname"],
          lastname: values["lastname"],
          password: values["password"],
          email: values["email"],
          phone: values["phone"],
          image_url: values["image_url"],
          brandId: parseInt(values["brandId"]),
          retailerId: parseInt(values["retailerId"]),
          accountType: "retailer",
        }
        // save new user
        dispatch(onAddNewUser(newUser))
        validation.resetForm()
      }
      toggle()
    },
  })

  const handelImage = event => {
    setLoading(true)
    let formData = new FormData()
    formData.set("file", event.target.files[0])
    upload(formData)
      .then(({ path }) => {
        setLoading(false)
        validation.setFieldValue("image_url", path)
      })
      .catch(e => {
        setLoading(false)
        console.log(e.response)
      })
  }

  const handleFileUpload = e => {
    e.preventDefault()
    fileRef?.current?.click()
  }

  const resetFileHandler = e => {
    e.preventDefault()
    validation.setFieldValue("imageUrl", "")
  }

  const handleChangeStore = async e => {
    validation.handleChange(e)
    let store = retailersData.find(store => store.id == e.target.value)

    validation.setFieldValue("retailerId", store?.id)
    validation.setFieldValue("phone", store?.phone)
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit User") : props.t("Add User")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-5">
                <Label className="form-label">{props.t("Image")}</Label>
                <div className="row">
                  <div className="col-12">
                    <div className="position-relative">
                      <button
                        className="btn btn-light w-100"
                        onClick={handleFileUpload}
                      >
                        {!loading &&
                          !validation.values.image_url &&
                          props.t("Upload Image")}
                        {loading && (
                          <Spinner className="text-center mt-1" size="sm" />
                        )}
                        {!loading &&
                          validation.values?.image_url?.split("/")[
                            validation.values?.image_url?.split("/").length - 1
                          ]}
                      </button>
                      <span
                        className="btn btn-info btn-icon sketch-picker ms-n4 mt-n2 rounded-circle"
                        onClick={resetFileHandler}
                      >
                        <i className="bx bx-x bx-xs"></i>
                      </span>
                      <Input
                        innerRef={fileRef}
                        name="image_url"
                        type="file"
                        hidden
                        validate={{
                          required: { value: true },
                        }}
                        accept=".png, .jpg, .jpeg"
                        onChange={handelImage}
                        onBlur={validation.handleBlur}
                        //value={convertToBlob(validation.values.image_url)}
                        invalid={
                          validation.touched.logo && validation.errors.logo
                            ? true
                            : false
                        }
                      />
                      {/* {!loading && validation.values.image_url && isEdit && */}
                      {/* (<i
                        className="bx bx-x bx-sm float-end me-3"
                        //type="button"
                        onClick={onRemove}
                      />) */}
                      {/* } */}
                    </div>
                  </div>
                </div>
                {validation.touched.logo && validation.errors.logo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.logo}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Retailer")}</Label>
                <Input
                  name="brandId"
                  type="select"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.brandId || ""}
                  invalid={
                    validation.touched.brandId && validation.errors.brandId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {brandData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name || "---"}
                    </option>
                  ))}
                </Input>
                {validation.touched.brandId && validation.errors.brandId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.brandId}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Store")}</Label>
                <Input
                  name="retailerId"
                  type="select"
                  validate={{
                    required: { value: true },
                  }}
                  // onChange={validation.handleChange}
                  onChange={e => handleChangeStore(e)}
                  onBlur={validation.handleBlur}
                  value={validation.values.retailerId || ""}
                  invalid={
                    validation.touched.retailerId &&
                    validation.errors.retailerId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {retailersData
                    ?.filter(
                      item =>
                        item.brandId === parseInt(validation.values.brandId) &&
                        item.status == "active"
                    )
                    ?.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name || "---"}
                      </option>
                    ))}
                </Input>

                {validation.touched.retailerId &&
                validation.errors.retailerId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.retailerId}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("First name")}</Label>
                <Input
                  name="firstname"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.firstname || ""}
                  invalid={
                    validation.touched.firstname && validation.errors.firstname
                      ? true
                      : false
                  }
                />
                {validation.touched.firstname && validation.errors.firstname ? (
                  <FormFeedback type="invalid">
                    {validation.errors.firstname}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Last name")}</Label>
                <Input
                  name="lastname"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lastname || ""}
                  invalid={
                    validation.touched.lastname && validation.errors.lastname
                      ? true
                      : false
                  }
                />
                {validation.touched.lastname && validation.errors.lastname ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lastname}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Email")}</Label>
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Password")}</Label>
                <Input
                  name="password"
                  type="password"
                  //placeholder={isEdit ? "If you don't wanna change the password leave this input empty" : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>

              {/* <div className="mb-3">
                <Label className="form-label">{props.t("Phone")}</Label>
                <Input
                  name="phone"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone || ""}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div> */}
              <div className="mb-3">
                <PhoneInput
                  country={phonePicker}
                  name="phone"
                  value={validation.values.phone || ""}
                  placeholder=""
                  onChange={phone => validation.setFieldValue("phone", phone)}
                  onBlur={validation.handleBlur}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  isValid={validation.touched.phone && !validation.errors.phone}
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-primary save-user">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(UserAddEdit)
