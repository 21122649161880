import React, { useEffect, useState } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { upload } from "helpers/api_helper"
import { useSelector, useDispatch } from "react-redux"
import {
  createMDMBrand,
  getMDMCategories,
  getServices,
  updateMDMBrand,
} from "store/actions"
import { withTranslation } from "react-i18next"
import { DatePicker } from "reactstrap-date-picker"
import "react-date-range/dist/styles.css"
import "react-date-range/dist/theme/default.css"
import moment from "moment"

const BrandModal = props => {
  const dispatch = useDispatch()
  const { modal, toggle, brand, isEdit } = props
  const [loading, setLoading] = useState(false)
  const { categoriesData } = useSelector(state => state.categories)
  const { services } = useSelector(state => state.services)
  const { user } = useSelector(state => state.Login)

  useEffect(() => {
    dispatch(getMDMCategories())
    dispatch(getServices({ page: 1, limit: 1000 }))
  }, [])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (brand && brand.id) || "",
      name: (brand && brand.name) || "",
      status: (brand && brand.status) || "active",
      imageUrl: (brand && brand.imageUrl) || "",
      coverImageUrl: (brand && brand.coverImageUrl) || "",
      categoryId: (brand && brand.categoryId) || null,
      subcategoryId: (brand && brand.subcategoryId) || null,
      nbStores: (brand && brand.nbStores) || 0,
      cards: (brand && brand.cards) || [],
      contractValidityFrom: (brand && brand.contract?.from) || null,
      contractValidityTo: (brand && brand.contract?.to) || null,
      serviceId: (brand && brand.serviceId) || null,
      discount: (brand && brand.discount) || 0,
      services: (brand && brand.contract?.services) || [],
      description: (brand && brand.description) || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Please Enter Retailer name")),
      // nameFr: Yup.string().required(props.t("Please Enter Your French name")),
      // nameAr: Yup.string().required(props.t("Please Enter Your Arabic Name")),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updatedBrand = {
          id: brand.id,
          name: values.name,
          status: values.status,
          imageUrl: values.imageUrl,
          coverImageUrl: values.coverImageUrl,
          categoryId: parseInt(values.categoryId),
          subcategoryId: parseInt(values.subcategoryId),
          nbStores: values.nbStores,
          cards: values.cards,
          description: values.description,
        }

        const contract = {
          ...(isEdit && brand?.contract?.id ? { id: brand?.contract?.id } : {}),
          brandId: brand?.id,
          services: values["services"]
            ?.filter(item => item.serviceId)
            ?.map(item => ({
              ...item,
              serviceId: parseInt(item.serviceId),
            })),
          from: values["contractValidityFrom"],
          to: values["contractValidityTo"],
          status: "active",
          agentId: user?.id || null,
        }
        // update order
        dispatch(updateMDMBrand({ brand: updatedBrand, contract }))
        validation.resetForm()
      } else {
        const newBrand = {
          name: values["name"],
          status: values["status"],
          imageUrl: values["imageUrl"],
          coverImageUrl: values["coverImageUrl"],
          ...(values["categoryId"]
            ? { categoryId: parseInt(values["categoryId"]) }
            : {}),
          ...(values["subcategoryId"]
            ? { subcategoryId: parseInt(values["subcategoryId"]) }
            : {}),
          nbStores: values["nbStores"],
          cards: values["cards"],
          description: values["description"],
        }

        const newContract = {
          services: values["services"]
            ?.filter(item => item.serviceId)
            ?.map(item => ({
              ...item,
              serviceId: parseInt(item.serviceId),
            })),
          from: values["contractValidityFrom"],
          to: values["contractValidityTo"],
          status: "active",
          agentId: user?.id || null,
        }
        console.log("newBrand", newBrand, newContract)
        // save new brand
        dispatch(createMDMBrand({ brand: newBrand, contract: newContract }))
        validation.resetForm()
      }
      toggle()
    },
  })
  const handelImage = (event, name) => {
    setLoading(true)
    let formData = new FormData()
    formData.set("file", event.target.files[0])
    upload(formData)
      .then(({ path }) => {
        setLoading(false)
        validation.setFieldValue(name, path)
      })
      .catch(e => {
        setLoading(false)
        // console.log(e.response)
      })
  }

  const handelCardsImage = event => {
    setLoading(true)
    let formData = new FormData()
    formData.set("file", event.target.files[0])
    upload(formData)
      .then(({ path }) => {
        setLoading(false)
        validation.setFieldValue("cards", [...validation.values.cards, path])
      })
      .catch(e => {
        setLoading(false)
        // console.log(e.response)
      })
  }

  const removeImageHandler = (e, path) => {
    e.preventDefault()
    validation.setFieldValue(
      "cards",
      validation.values.cards?.filter(item => item !== path)
    )
  }

  const addServiceHandler = e => {
    e.stopPropagation()
    const services = validation.values.services
    validation.setFieldValue("services", [
      ...services,
      { discount: 0, serviceId: "" },
    ])
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit Retailer") : props.t("Add Retailer")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">{props.t("Name")}</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  invalid={
                    validation.touched.name && validation.errors.name
                      ? true
                      : false
                  }
                />
                {validation.touched.name && validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Description")}</Label>
                <Input
                  name="description"
                  type="textarea"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid">
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Category")}</Label>
                <Input
                  name="categoryId"
                  type="select"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.categoryId || ""}
                  invalid={
                    validation.touched.categoryId &&
                    validation.errors.categoryId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {categoriesData
                    .filter(item => item.parentId == null)
                    .map(item => (
                      <option key={item.id} value={item.id}>
                        {item.nameEn || "---"}
                      </option>
                    ))}
                </Input>
                <p className="card-title-desc font-size-11 ms-1 me-1"></p>
                {validation.touched.categoryId &&
                validation.errors.categoryId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.categoryId}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Sub Category")}</Label>
                <Input
                  name="subcategoryId"
                  type="select"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.subcategoryId || ""}
                  invalid={
                    validation.touched.subcategoryId &&
                    validation.errors.subcategoryId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {categoriesData
                    ?.filter(
                      item => item.parentId == validation.values.categoryId
                    )
                    ?.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.nameEn || "---"}
                      </option>
                    ))}
                </Input>
                <p className="card-title-desc font-size-11 ms-1 me-1"></p>
                {validation.touched.subcategoryId &&
                validation.errors.subcategoryId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.subcategoryId}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">
                  {props.t("Number of stores")}
                </Label>
                <Input
                  name="nbStores"
                  type="number"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nbStores || ""}
                  invalid={
                    validation.touched.nbStores && validation.errors.nbStores
                      ? true
                      : false
                  }
                />
                {validation.touched.nbStores && validation.errors.nbStores ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nbStores}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-5">
                <Label className="form-label">Logo</Label>
                <div className="row">
                  <div className="col-12">
                    <div className="position-relative">
                      <Input
                        className="position-absolute"
                        // id="inputGroupFile04"
                        // aria-describedby="inputGroupFileAddon04"
                        // aria-label="Upload"
                        name="imageUrl"
                        type="file"
                        validate={{
                          required: { value: true },
                        }}
                        accept=".png, .jpg, .jpeg"
                        onChange={event => handelImage(event, "imageUrl")}
                        onBlur={validation.handleBlur}
                        //value={validation.values.logo || ""}
                        invalid={
                          validation.touched.logo && validation.errors.logo
                            ? true
                            : false
                        }
                      />
                      {loading && (
                        <Spinner className="mt-2 float-end me-3" size="sm" />
                      )}
                      {/* <i
                          className="far fa-times-circle"
                          id="inputGroupFileAddon04" type="button" onClick={onRemove}
                        ></i>
                        <i className="dripicons-document-delete"/> */}
                    </div>
                  </div>
                </div>
                {validation.touched.logo && validation.errors.logo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.logo}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-5">
                <Label className="form-label">Cover Image</Label>
                <div className="row">
                  <div className="col-12">
                    <div className="position-relative">
                      <Input
                        className="position-absolute"
                        // id="inputGroupFile04"
                        // aria-describedby="inputGroupFileAddon04"
                        // aria-label="Upload"
                        name="coverImageUrl"
                        type="file"
                        validate={{
                          required: { value: true },
                        }}
                        accept=".png, .jpg, .jpeg"
                        onChange={event => handelImage(event, "coverImageUrl")}
                        onBlur={validation.handleBlur}
                        //value={validation.values.coverImageUrl || ""}
                        invalid={
                          validation.touched.coverImageUrl &&
                          validation.errors.coverImageUrl
                            ? true
                            : false
                        }
                      />
                      {loading && (
                        <Spinner className="mt-2 float-end me-3" size="sm" />
                      )}
                      {/* <i
                          className="far fa-times-circle"
                          id="inputGroupFileAddon04" type="button" onClick={onRemove}
                        ></i>
                        <i className="dripicons-document-delete"/> */}
                    </div>
                  </div>
                </div>
                {validation.touched.coverImageUrl &&
                validation.errors.coverImageUrl ? (
                  <FormFeedback type="invalid">
                    {validation.errors.coverImageUrl}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-5">
                <Label className="form-label">Cards</Label>
                <div className="row">
                  <div className="col-12">
                    <div className="position-relative">
                      <Input
                        className="position-absolute"
                        // id="inputGroupFile04"
                        // aria-describedby="inputGroupFileAddon04"
                        // aria-label="Upload"
                        name="cards"
                        type="file"
                        validate={{
                          required: { value: true },
                        }}
                        accept=".png, .jpg, .jpeg"
                        onChange={handelCardsImage}
                        onBlur={validation.handleBlur}
                        //value={validation.values.logo || ""}
                        invalid={
                          validation.touched.cards && validation.errors.cards
                            ? true
                            : false
                        }
                      />
                      {loading && (
                        <Spinner className="mt-2 float-end me-3" size="sm" />
                      )}
                      {/* <i
                          className="far fa-times-circle"
                          id="inputGroupFileAddon04" type="button" onClick={onRemove}
                        ></i>
                        <i className="dripicons-document-delete"/> */}
                    </div>
                  </div>
                </div>
                {validation.touched.cards && validation.errors.cards ? (
                  <FormFeedback type="invalid">
                    {validation.errors.cards}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-5">
                {validation.values.cards?.map((item, index) => (
                  <div key={index} className="col-12 my-3 position-relative">
                    {/* {!isEdit ? ( */}
                    <span
                      className="btn btn-info btn-icon sketch-picker rounded-circle font-800"
                      style={{ right: 0, height: 18, width: 18 }}
                      onClick={event => removeImageHandler(event, item)}
                    >
                      <i className="bx bx-x bx-xs"></i>
                    </span>
                    {/* ) : null} */}
                    <div className="row px-2 font-size-11">{item}</div>
                  </div>
                ))}
              </div>
              <div className="mb-3 d-flex gap-3">
                <div>
                  <Label className="form-label">
                    {props.t("Contract Validity")}
                  </Label>
                  <DatePicker
                    id="contract-validity-from-datepicker"
                    value={validation.values.contractValidityFrom}
                    onChange={(v, f) =>
                      validation.setFieldValue(
                        "contractValidityFrom",
                        moment(v).format("YYYY-MM-DD")
                      )
                    }
                  />
                </div>
                <div>
                  <Label className="form-label">
                    {props.t("Contract Validity")}
                  </Label>
                  <DatePicker
                    id="contract-validity-to-datepicker"
                    value={validation.values.contractValidityTo}
                    onChange={(v, f) =>
                      validation.setFieldValue(
                        "contractValidityTo",
                        moment(v).format("YYYY-MM-DD")
                      )
                    }
                  />
                </div>
              </div>
              {validation.values.services?.map((item, index) => (
                <div key={index} className="mb-3 ">
                  <Label className="form-label">
                    {props.t("Service Name")}
                  </Label>
                  <div className="d-flex pe-2">
                    <Input
                      className="w-75"
                      name={`services.${index}.serviceId`}
                      type="select"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={item?.serviceId || ""}
                      invalid={
                        validation.touched.services?.[index]?.serviceId &&
                        validation.errors.services?.[index]?.serviceId
                          ? true
                          : false
                      }
                    >
                      <option key="empty" disabled></option>
                      {services.map(service => (
                        <option key={service.id} value={service.id}>
                          {service.nameEn || "---"}
                        </option>
                      ))}
                    </Input>
                    <p className="card-title-desc font-size-11 ms-1 me-1"></p>
                    {validation.touched.services?.[index]?.serviceId &&
                    validation.errors.services?.[index]?.serviceId ? (
                      <FormFeedback type="invalid">
                        {validation.errors.services?.[index]?.serviceId}
                      </FormFeedback>
                    ) : null}
                    <Input
                      className="w-25"
                      name={`services.${index}.discount`}
                      type="number"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={item?.discount || ""}
                      invalid={
                        validation.touched.services?.[index]?.discount &&
                        validation.errors.services?.[index]?.discount
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              ))}
              <div className="mb-3">
                <div className="text-end">
                  <button
                    type="button"
                    onClick={addServiceHandler}
                    className="btn btn-info save-user w-100"
                  >
                    {props.t("New service")}
                  </button>
                </div>
              </div>
              {/* <div className="mb-3">
                <Label className="form-label">{props.t("French Name")}</Label>
                <Input
                  name="nameFr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameFr || ""}
                  invalid={
                    validation.touched.nameFr && validation.errors.nameFr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameFr && validation.errors.nameFr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameFr}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Arabic Name")}</Label>
                <Input
                  name="nameAr"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.nameAr || ""}
                  invalid={
                    validation.touched.nameAr && validation.errors.nameAr
                      ? true
                      : false
                  }
                />
                {validation.touched.nameAr && validation.errors.nameAr ? (
                  <FormFeedback type="invalid">
                    {validation.errors.nameAr}
                  </FormFeedback>
                ) : null}
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(BrandModal)
