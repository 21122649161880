import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import "bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../../components/Common/TableContainer"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import DeleteModal from "../../../components/Common/DeleteModal"

import { getCardType, updateCardType } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"
import { withTranslation } from "react-i18next"
import CardTypeModal from "./CardTypeModal"
import { checkPermission } from "helpers/functions"

function ParrainageType(props) {
  //meta title
  document.title = "Mazaya"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [cardType, setCardType] = useState(null)
  const [show, setShow] = useState(false)
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
    status: "active",
  })

  const dispatch = useDispatch()

  const {
    cardTypeData,
    error,
    success,
    nextPage,
    currentPage,
    previousPage,
    total,
    limit,
    loading,
  } = useSelector(state => state.cardTypes)
  const userRoles = useSelector(state => state.Login.user?.roles)

  useEffect(() => {
    dispatch(getCardType(filter))
  }, [dispatch, filter])

  useEffect(() => {
    if (!isEmpty(cardTypeData) && !!isEdit) {
      setIsEdit(false)
    }
  }, [cardTypeData])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setCardType(null)
    } else {
      setModal(true)
    }
  }

  const toggleShow = () => {
    setShow(!show)
  }

  const handleOrderClick = arg => {
    const cardType = arg
    setCardType({
      ...cardType,
    })

    setIsEdit(true)

    toggle()
  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = cardType => {
    setCardType(cardType)
    setDeleteModal(true)
  }

  const handleDeleteLevel = () => {
    if (cardType.id) {
      dispatch(updateCardType({ id: cardType.id, status: "archived" }))
      setDeleteModal(false)
      dispatch(getCardType(filter))
    }
  }
  const handleOrderClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("Name"),
        accessor: "name",
        disableFilters: true,
        accessor: cellProps => {
          return (
            <div
              className="external-event"
              onClick={() => {
                setCardType(cellProps)
                toggleShow()
              }}
            >
              {cellProps.name}
            </div>
          )
        },
      },
      {
        Header: props.t("Price TND"),
        accessor: "value",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.price_TND || 0}</>
        },
      },
      {
        Header: props.t("Price DZD"),
        accessor: "value",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.price_DZD || 0}</>
        },
      },
      {
        Header: props.t("Price LYD"),
        accessor: "value",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.price_LYD || 0}</>
        },
      },
      {
        Header: props.t("Description"),
        accessor: "description",
        disableFilters: true,
        accessor: cellProps => {
          return <>{cellProps.description || "-----"}</>
        },
      },

      {
        Header: props.t("Action"),
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="text-center">
              <Link
                to="#"
                className="text-tip"
                onClick={() => {
                  const orderData = cellProps.row.original
                  handleOrderClick(orderData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original
                  onClickDelete(orderData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const setPageNumber = activePage => {
    setFilter(prevFilter => {
      return { ...prevFilter, page: activePage }
    })
  }
  const handleLimit = limit => {
    setFilter(prevFilter => {
      return { ...prevFilter, limit: limit }
    })
  }

  return (
    <React.Fragment>
      <CardTypeModal
        modal={modal}
        toggle={toggle}
        isEdit={isEdit}
        cardType={cardType}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteLevel}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Card Types")}
            breadcrumbItem={props.t("Card Types List")}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={cardTypeData.filter(
                      cardType => cardType.name != "free"
                    )}
                    //isGlobalFilter={true}
                    isAddOptions
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={100}
                    setPageNumber={setPageNumber}
                    currentPage={currentPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    setLimit={handleLimit}
                    total={total}
                    limit={limit}
                    loading={loading}
                    success={success}
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
ParrainageType.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(ParrainageType)
