import React, { useEffect } from "react"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap"
import {
  addNewAdmin as onAddNewUser,
  updateAdmin as onUpdateUser,
} from "store/admins/actions"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { withTranslation } from "react-i18next"
import { getOffices } from "store/actions"

const UserAddEdit = props => {
  const { user, isEdit, modal, toggle } = props
  const dispatch = useDispatch()
  const { roles } = useSelector(state => state.Roles)
  const { officesData } = useSelector(state => state.offices)

  useEffect(() => {
    dispatch(getOffices({ status: "active", page: 1, limit: 100 }))
  }, [dispatch])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      firstname: (user && user.firstname) || "",
      lastname: (user && user.lastname) || "",
      phone: (user && user.phone) || "",
      email: (user && user.email) || "",
      officeId: (user && user.officeId) || "",
      commissionRate: (user && user.commissionRate) || "",
      password: "",
      roles: (user && user.roles) || [],
    },

    validationSchema: Yup.object({
      firstname: Yup.string().required(props.t("Please Enter Your First Name")),
      //   .matches(props.t("^[a-zA-Z]+$", "only latin words")),
      lastname: Yup.string().required(props.t("Please Enter Your Last Name")),
      //   .matches(props.t("^[a-zA-Z]+$", "only latin words")),
      email: Yup.string().required(props.t("Please Enter Your Email")),
      password: !user
        ? Yup.string().required(props.t("Please Enter Your Password"))
        : null,
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateUser = {
          id: user.id,
          firstname: values.firstname,
          phone: values.phone,
          lastname: values.lastname,
          officeId: values.officeId,
          commissionRate: values.commissionRate,
          ...(values.password && { password: values.password }),
          roles: values.roles,
          email: values.email,
        }
        //console.log(updateUser)
        // update user
        dispatch(onUpdateUser(updateUser))
        validation.resetForm()
        //setIsEdit(false);
      } else {
        const newUser = {
          firstname: values["firstname"],
          lastname: values["lastname"],
          phone: values["phone"],
          password: values["password"],
          email: values["email"],
          roles: values["roles"],
          officeId: values["officeId"],
          commissionRate: values["commissionRate"],
        }
        // save new user
        dispatch(onAddNewUser(newUser))
        validation.resetForm()
      }
      toggle()
    },
  })

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} tag="h4">
        {!!isEdit ? props.t("Edit User") : props.t("Add User")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row form>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">{props.t("First name")}</Label>
                <Input
                  name="firstname"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.firstname || ""}
                  invalid={
                    validation.touched.firstname && validation.errors.firstname
                      ? true
                      : false
                  }
                />
                {validation.touched.firstname && validation.errors.firstname ? (
                  <FormFeedback type="invalid">
                    {validation.errors.firstname}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Last name")}</Label>
                <Input
                  name="lastname"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lastname || ""}
                  invalid={
                    validation.touched.lastname && validation.errors.lastname
                      ? true
                      : false
                  }
                />
                {validation.touched.lastname && validation.errors.lastname ? (
                  <FormFeedback type="invalid">
                    {validation.errors.lastname}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Phone")}</Label>
                <Input
                  name="phone"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone || ""}
                  invalid={
                    validation.touched.phone && validation.errors.phone
                      ? true
                      : false
                  }
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Office")}</Label>
                <Input
                  name="officeId"
                  type="select"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.officeId || ""}
                  invalid={
                    validation.touched.officeId && validation.errors.officeId
                      ? true
                      : false
                  }
                >
                  <option key="empty" disabled></option>
                  {officesData
                    .filter(offices => offices.status != "archived")
                    .map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name || "---"}
                      </option>
                    ))}
                </Input>
                <p className="card-title-desc font-size-11 ms-1 me-1"></p>
                {validation.touched.officeId && validation.errors.officeId ? (
                  <FormFeedback type="invalid">
                    {validation.errors.officeId}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Password")}</Label>
                <Input
                  name="password"
                  type="password"
                  //   placeholder={
                  //     isEdit
                  //       ? "If you don't wanna change the password leave this input empty"
                  //       : ""
                  //   }
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">{props.t("Email")}</Label>
                <Input
                  name="email"
                  label="Email"
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
              <div className="mb-3">
                <Label className="form-label">
                  {props.t("commission Rate")}
                </Label>
                <Input
                  name="commissionRate"
                  label="commissionRate"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.commissionRate || ""}
                  invalid={
                    validation.touched.commissionRate &&
                    validation.errors.commissionRate
                      ? true
                      : false
                  }
                />
                {validation.touched.commissionRate &&
                validation.errors.commissionRate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.commissionRate}
                  </FormFeedback>
                ) : null}
              </div>
              <Label className="form-label mb-3">{props.t("Roles")}</Label>
              {roles.length > 0 &&
                roles.map(role => (
                  <div key={role?.id} className="form-check mb-1">
                    <Input
                      id={role?.id}
                      type="checkbox"
                      name="roles"
                      className="form-check-input"
                      onClick={e => {
                        if (!e.target.checked) {
                          validation.setFieldValue("roles", [
                            ...validation.values.roles,
                            role,
                          ])
                        } else {
                          validation.setFieldValue("roles", [
                            ...validation.values.roles.filter(
                              el => el.id != role.id
                            ),
                          ])
                        }
                      }}
                      onBlur={validation.handleBlur}
                      checked={
                        validation.values?.roles?.some(
                          el => el.name == role.name
                        ) || ""
                      }
                      invalid={
                        validation.touched.roles && validation.errors.roles
                          ? true
                          : false
                      }
                    />
                    {validation.touched.roles &&
                    validation.errors.roles?.length < 1 ? (
                      <FormFeedback type="invalid">
                        {validation.errors.roles}
                      </FormFeedback>
                    ) : null}
                    <Label className="form-check-label" htmlFor="admin">
                      {role?.name}
                    </Label>
                  </div>
                ))}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-primary save-user">
                  {props.t("Save")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default withTranslation()(UserAddEdit)
